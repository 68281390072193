export const BACKEND_URL = 'https://backend.litethinking.com';
export const FRONTENDLMS_URL = 'https://lms.litethinking.com';
export const FRONTMICROSITE = 'https://payments.litethinking.com';
export const FRONTEND_URL = 'https://litethinking.com';
export const CREDENTIALS_URL = 'https://credentials.litethinking.com';
export const JOBS_URL = 'https://jobs.litethinking.com';

// export const BACKEND_URL = 'https://pre-backend.litethinking.com';
// export const FRONTEND_URL = 'https://pre.litethinking.com';
// export const FRONTENDLMS_URL = 'https://prelms.litethinking.com';
// export const FRONTMICROSITE = 'https://payments.litethinking.com';
// export const CREDENTIALS_URL = 'https://precredentials.litethinking.com';
// export const JOBS_URL = 'https://prejobs.litethinking.com';

export const FRONTENDCRM_URL = 'https://crm.business-litethinking.com/';
export const LOCATION_URL = 'https://ipapi.co/json/';
export const OFFICE_365_LOGIN = 'https://www.outlook.com/litethinking.com';

// // Delete:
// export const BACKEND_URL = "http://localhost:8000";
// export const FRONTEND_URL = 'http://localhost:3000';
// export const FRONTENDLMS_URL = "http://localhost:3001";
// export const CREDENTIALS_URL = "http://localhost:3002";
// export const FRONTMICROSITE = "http://localhost:3003";

export const PREVIEW_URL = 'https://getlinkpreview.onrender.com/';

export const API_CODE_VERIFICATION = '/api-code-verification/';
export const LOGIN_PATH = '/api-token/';
export const REGISTER_PATH = '/api-virtual-campus-users/';
export const LOGIN_SOCIAL_PATH = '/api-social-auth/';
export const COURSES_LIST_PATH = '/api-virtual-campus-courses/';
export const LANGUAGE_LIST_PATH = '/api-multilanguage-list/';
export const GET_API_BANNER_VIDEOS = '/api-virtual-campus-introductory-banner/';
export const GENERAL_BANNER_LIST_PATH = '/api-virtual-campus-general-banner/';
export const CONSULTANTS_LIST_PATH = '/api-virtual-campus-consultants/';
export const GET_PROFILE = '/api-virtual-campus-users/';
export const REGISTER_BUY_PATH = '/api-users-courses-modes';
export const GET_COUNTRIES = '/api-countries/';
export const GET_COUNTRIES_MULTILANGUAJE = '/api-countries-multilanguaje/';
export const GET_CITIES = '/api-cities/';
export const GET_PARAMETERS_PATH = '/api-parameters/';
export const INDUSTRIES_API_PATH = '/api-virtual-campus-industries/';
export const COMPANIES_LIST_PATH = '/api-virtual-campus-companies/';
export const REGISTER_USER_PATH = '/api-virtual-campus-user-register/';
export const DOCUMENTS_PATH = '/api-documents/';
export const GET_PARTNERS_LIST_PATH = '/api-virtual-campus-partners/';
export const GET_STRATEGIC_LINES_LIST_PATH = '/api-virtual-campus-strategic-lines/';
export const GET_PORTFOLIOS_LIST_PATH = '/api-virtual-campus-portfolios/';
export const GET_NEWS_LIST_PATH = '/api-virtual-campus-news/';
export const GET_CONSULTANTS_BY_COURSE = '/api-consultant-by-course/';
export const GET_SCHEDULER_BY_COURSE = '/api-scheduler-by-course/';
export const GET_RESOURCES_BY_COURSE = '/api-resources-by-course/';
export const STRIPE_API = '/api-strip-integration/';
export const NEXT_EVENTS_PATH = '/api-next-events/';
export const GET_JOBS = '/api-jobs/';
export const LATEST_PRODUCTS = '/api-latest-products';
export const ONLINE_CONTENT = '/api-online-content';
export const CERTIFICATIONS = '/api-certifications-by-course';
export const GET_INFORMATION_SYSTEM = '/api-information-system/';
export const GET_CHALLENGE = '/api-challenge-only/';
export const FORM_IMG = '/api-form-image/';
export const API_RESULT = '/api-result/';
export const API_SCALES = '/api-challenge-scales/';
export const API_CHALLENGE_PRESENTATION = '/api-challenge-presentation/';
export const PUT_REGISTER_SOCIAL_PATH = '/api-virtual-campus-user-update-social/';
export const API_SOCIAL_MEDIA = '/api-social-media/';
export const API_JOB_QUESTION = '/api-job-filter-list/';
export const API_POSTULATION = '/api-postulation/';
export const API_VIRTUAL_CAMPUS_SPONSOR_MEDIA = '/api-virtual-campus-sponsor-media/';
export const API_PARAMETERS_COMPONENT = '/api-parameters-component';
export const API_ENGLISH_REGISTER = '/api-english-register/';
export const API_REGISTRATION_CHALLENGE_PRESENTATION = '/api-registration-challenge-presentation/';
export const API_GET_RESUME = '/api-resume/';
export const API_REGISTRATION_CHALLENGE_RESULT = '/api-registration-challenge-result/';
export const API_LEADER_TESTIMONIAL = '/api-leader-testimonial/';
export const API_SYNCRONOUSCLASS_VIEW = '/api-synchronousclass-api-view/';
export const API_ABOUT_COURSE = '/api-about-course/';
export const API_INFO_FILIAL = '/api-info-filial';
export const API_SCHEDULER_INFO = '/api-language-scheduler-info/';
export const API_USER_PHONE = '/api-user-phone/';
export const API_USER_SKILLS = '/api-user-skills/';
export const API_USER_DOCUMENT_TYPE = '/api-user-document-type/';
export const API_USER_REFERENCE = '/api-user-reference/';
export const API_USER_ENROLLMENT = '/api-user-enrollment/';
export const API_DOCUMENT_TYPE_COUNTRIES = '/api-document-type-countries/';
export const API_GET_SKILLS = '/api-skills/';
export const API_GET_NAME_SCHEDULER = '/api-scheduler-name/';
export const API_PERSONAL_DATA = '/api-personal-data/';
export const API_CREATE_USER = '/create-user-validated/';

export const API_TOKEN = '75853e402747730aaffc3c3b2fbb687cc46111da';
